import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { AuthModule } from 'angular-auth-oidc-client';
import {
  TranslateModule,
  TranslateLoader,
  TranslateParser,
} from '@ngx-translate/core';
import { TranslateICUParser } from 'ngx-translate-parser-plural-select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { ConfirmationService } from 'primeng/api';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SharedModule } from './shared/shared.module';
import { AppCache, LocalStorageAppCache } from './core/services/app-cache/app-cache.service';
import { authInterceptorProviders } from './core/interceptor/auth.interceptor';
import { provideHotToastConfig } from '@ngxpert/hot-toast';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

const webObj = [
  SharedModule,
  CommonModule,
  BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  FormsModule,
  ConfirmDialogModule,
  EditorModule,
  NgIdleKeepaliveModule.forRoot(),
  AuthModule.forRoot({
    config: {
      authority: 'https://spaxel.onelogin.com/oidc/2',
      redirectUrl: environment.redirect_uri,
      postLoginRoute: environment.post_login_route,
      postLogoutRedirectUri: environment.post_logout_redirect_uri,
      clientId: environment.client_id,
      scope: 'openid profile',
      responseType: 'id_token token',
      silentRenew: true,
      useRefreshToken:true,
      silentRenewUrl: `${window.location.origin}/silent-refresh.html`,
      renewTimeBeforeTokenExpiresInSeconds: 10,
      unauthorizedRoute: '',
    },
  }),
  TranslateModule.forRoot({
    parser: {
      provide: TranslateParser,
      useClass: TranslateICUParser,
    },
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
    defaultLanguage: 'en',
  }),
];


@NgModule({
  imports: webObj,
  providers: [
    provideHttpClient(),
    provideHotToastConfig({ visibleToasts: 1 }),
    { provide: AppCache, useClass: LocalStorageAppCache },
    ConfirmationService,
    authInterceptorProviders,
    { provide: 'environment', useValue: environment }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
