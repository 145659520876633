import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoomData } from 'shared-models';

@Component({
  selector: 'app-agent-mobile-calling-dialog',
  templateUrl: './agent-mobile-calling-dialog.component.html',
  styleUrls: ['./agent-mobile-calling-dialog.component.scss']
})
export class AgentMobileCallingDialogComponent {
  address = "";
  unit = "";
  tenantName = "";
  title = "Incoming Call From Tenant (Mobile)";

  constructor(@Inject(MAT_DIALOG_DATA) public data: RoomData) {
    this.address = data.Address;
    this.unit = data.UnitName || "Leasing";
    this.tenantName = data.TenantName;
    this.tenantName = data.TenantName;
    this.title = "Tenant Calling...";
  }
}
