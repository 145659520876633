<div class="header">
     <span class="title">Client has uploaded a file</span>
     <i class="fa-solid fa-x" (click)="close()" (keyup)="close()" tabindex="0"></i>
</div>
<div class="content">
    <span class="notification">To view the file,
        <span class="link" (click)="openFileInNewTab()" (keyup)="openFileInNewTab()" tabindex="0" >click here to view.</span>
    </span>
    <span class="notification">To download the file, <span class="link" (click)="downloadScannedFile()" (keyup)="downloadScannedFile()" tabindex="0">
        click here.</span>
    </span>
</div>
