import { Injectable } from '@angular/core';
import { WebrtcService } from 'shared-services';
import { AuthenticatedUserService } from 'shared-services';

@Injectable({
  providedIn: 'root'
})
export class GlobalHelperService {

  constructor(private webrtc: WebrtcService, private authenticatedUserService: AuthenticatedUserService) { }

  public async getRoles() {
    const localStorageUser = await this.authenticatedUserService.getClaims();

    return Array.isArray(localStorageUser.roles) ? localStorageUser.roles : [localStorageUser.roles];
  }

  public async getCallType() {
    const localStorageUser = await this.authenticatedUserService.getClaims();

    return !Array.isArray(localStorageUser.roles) ? localStorageUser.roles : "Agent"
  }

  public getUserForSignalR() {
    const user = {
      "role": this.getCallType(),
      "callType": this.getCallType(),
      "roles": this.getRoles(),
      "username": localStorage.username,
      "peerId": this.webrtc.peerId
    }

    return user;
  }
}
