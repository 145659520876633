<div class="layout-container" (window:beforeunload)="onUnload()">
  <app-header></app-header>
  <div class="layout-wrapper">
    <app-sidebar ></app-sidebar>
    <div class="content">
      <div *ngIf="showsToast && !onlineStatus" [class]="toastClass">
        <div>
          You are offline now, please check your internet connection!
        </div>

        <a class="close-btn" (click)="showsToast = !showsToast" (keyup)="showsToast = !showsToast" tabindex="0" style="cursor: pointer;">
          <small>Dismiss</small>
        </a>
      </div>
      <div *ngIf="showsToast && onlineStatus" class="toastClass2">
        <div>
          You are back online!
        </div>

        <a class="close-btn" (click)="showsToast = !showsToast" (keyup)="showsToast = !showsToast" tabindex="0"  style="cursor: pointer;">
          <small>Dismiss</small>
        </a>
      </div>

      <div class="mainTable">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog key="camInUseDialog" #cd header="Confirmation" icon="pi pi-exclamation-triangle" [baseZIndex]="999999">
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="OK"
      (click)="cd.accept()"
      aria-label="Confirm"
    ></button>
  </ng-template>
</p-confirmDialog>
