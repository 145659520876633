import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { FileUploadedToasterComponent } from './components/file-uploaded-toaster/file-uploaded-toaster.component';
import { LayoutComponent } from './layout/layout.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { RouterModule } from '@angular/router';
import { UserInfoComponent } from './layout/header/user-info/user-info.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipModule } from 'primeng/chip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AuthService } from '../core/services/auth.service';
import { SidebarItemComponent } from './layout/sidebar/sidebar-item/sidebar-item.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { UserIdleDialogComponent } from './layout/user-idle-dialog/user-idle-dialog.component';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { DataMissingErrorComponent } from './error-message/data-missing-error/data-missing-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { ScheduleCallbackDialogComponent } from './schedule-callback-dialog/schedule-callback-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmationService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { WebrtcComponent } from './webrtc/webrtc.component';
import { AppComponent } from '../app.component';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { RefreshRateComponent } from './refresh-rate/refresh-rate.component';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    AppComponent,
    FileUploadedToasterComponent,
    LayoutComponent,
    SidebarComponent,
    HeaderComponent,
    UserInfoComponent,
    SidebarItemComponent,
    UserIdleDialogComponent,
    DataMissingErrorComponent,
    ScheduleCallbackDialogComponent,
    WebrtcComponent,
    RefreshRateComponent,
  ],
  imports: [
    MessagesModule,
    MessageModule,
    MatButtonModule,
    MatDialogModule,
    DropdownModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ButtonModule,
    MenuModule,
    SelectButtonModule,
    TagModule,
    TooltipModule,
    ProgressSpinnerModule,
    TranslateModule,
    DialogModule,
    InputTextModule,
    InputMaskModule,
    InputTextareaModule,
    ConfirmDialogModule,
    SidebarModule,
  ],
  exports: [
    MessagesModule,
    MessageModule,
    LayoutComponent,
    HeaderComponent,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDividerModule,
    TableModule,
    ButtonModule,
    MenuModule,
    DynamicDialogModule,
    MultiSelectModule,
    CommonModule,
    FormsModule,
    ChipModule,
    MatSnackBarModule,
    WebrtcComponent,
    SkeletonModule,
    RefreshRateComponent,
  ],
  providers: [AuthService, ConfirmationService],
})
export class SharedModule { }
