import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RefreshRateService } from 'src/app/core/services/refresh-rate-service/refresh-rate.service';
interface Timing {
  text: string,
  milliseconds: number
}

@Component({
  selector: 'app-refresh-rate',
  templateUrl: './refresh-rate.component.html',
  styleUrls: ['./refresh-rate.component.scss']
})

export class RefreshRateComponent implements OnInit {
  timings: Timing[];
  selectedTiming: Timing;
  @Output() timing = new EventEmitter<any>()
  refreshRateInterval: NodeJS.Timer = null;
  childName = "";

  constructor(public refreshRateService: RefreshRateService) {
    this.childName = this.refreshRateService.name+":refreshRate";
    this.timings = [
      { text: 'Pause', milliseconds: 0 },
      { text: '5 sec', milliseconds: 5 * 1000 },
      { text: '10 sec', milliseconds: 10 * 1000 },
      { text: '30 sec', milliseconds: 30 * 1000 },
      { text: '1 min', milliseconds: 60 * 1000 },
      { text: '5 min', milliseconds: 60 * 1000 * 5 }
    ];

    this.selectedTiming = this.timings[2];
    if (localStorage.getItem(this.childName)) {
      this.selectedTiming = this.timings.find(t => t.milliseconds == +localStorage.getItem(this.childName));
    }
  }

  ngOnInit(): void {
    this.refreshRateService.refreshRate.next(true);
    const localStorageRefreshRate = localStorage.getItem(this.childName);
    this.setRefreshInterval(localStorageRefreshRate != null ? Number(localStorageRefreshRate) : 10000);
  }

  private setRefreshInterval(milisecs:number) {
    if (milisecs > 0) {
      clearInterval(this.refreshRateInterval);
      this.refreshRateInterval = setInterval(() => {
        this.refreshRateService.refreshRate.next(true);
      }, milisecs);
    }
    else{
      clearInterval(this.refreshRateInterval);
    }
  }

  timingChanged(event) {
    localStorage.setItem(this.childName, event.value.milliseconds);
    this.setRefreshInterval(event.value.milliseconds);
  }
}
