import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarItem } from 'shared-models'
import { ApiRequestsService, AuthenticatedUserService } from 'shared-services';
import { MenuItem } from 'primeng/api';
import { Roles } from 'shared-enums'
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  sidebarItems: SidebarItem[] = [];
  roles: string;
  isAdmin: boolean;
  name: string;
  isAgent: boolean;
  isMarketingAgent: boolean;
  isLockboxServiceManager: boolean;
  items: MenuItem[] = [
    {
      label: 'Log Out',
      icon: 'pi pi-fw pi-sign-out',
      command: () => this.signOut(),
    },
  ];
  unResolvedFilesCounter: number;
  unResolvedCallReschedulesCounter: number;
  unResolvedLedgersCounter: number;
  unverifiedMobileUsersCounter: number;
  constructor(
    private router: Router,
    private authenticatedUserService: AuthenticatedUserService,
    private apiRequests: ApiRequestsService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.setCurrentUserData();
    this.retrieveUserRoles();
    this.apiRequests.getOfflineUnresolvedFiles().subscribe((res) => {
      this.unResolvedFilesCounter = res;
      this.setSidebarItems();
    });

    this.apiRequests.getUnresolvedCallReschedules().subscribe((res) => {
      this.unResolvedCallReschedulesCounter = res;
    });

    this.apiRequests.getUnresolvedLedgers().subscribe((res) => {
      this.unResolvedLedgersCounter = res;
    });
    this.apiRequests.getUnverifiedMobileUsers().subscribe((res) => {
      this.unverifiedMobileUsersCounter = res;
    });
  }

  sidebarVisible: boolean;
  showSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  private signOut(): void {
    this.authenticatedUserService.logout().subscribe();
    this.router.navigate(['agent/thankyou']);
  }

  setSidebarItems(): void {
    this.sidebarItems = [];
    if (this.isAdmin == true) {
      this.sidebarItems.push({
        name: 'Users',
        icon: 'fa-user-group',
        route: '/admin/users',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'Kiosks',
        icon: 'fa-network-wired',
        route: '/admin/kiosk-management',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'Kiosks crashes',
        icon: 'fa-bug',
        route: '/admin/crashes',
        isSharedFiles: false,
        selected: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'Addresses / Properties',
        icon: 'fa-thin fa-location-dot',
        route: 'admin/addresses-properties',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      //fa-earth for the property
      this.sidebarItems.push({
        name: 'Units',
        icon: 'fa-solid fa-building',
        route: '/admin/units',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'Call Reasons',
        icon: 'fa-phone',
        route: '/admin/callreasons',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'Call Feedbacks',
        icon: 'fa-square-phone',
        route: '/admin/callfeedbacks',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });
    }

    if (this.isMarketingAgent == true || this.isAdmin == true) {
      this.sidebarItems.push({
        name: 'News',
        icon: 'fa-newspaper',
        route: '/admin/news',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });
    }

    if (this.isLockboxServiceManager == true || this.isAdmin == true) {

      this.sidebarItems.push({
        name: 'Self Guided Tours',
        icon: 'fas fa-user',
        route: '/agent/lockbox',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });
    }

    if (this.isAgent == true) {

      this.sidebarItems.push({
        name: 'Tenants',
        icon: 'fa-solid fa-users',
        route: '/agent/tenants',
        isSharedFiles: false,
        selected: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'Mobile Users',
        icon: 'fa-solid fa-users',
        route: '/agent/mobileusers',
        isSharedFiles: false,
        selected: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: this.unverifiedMobileUsersCounter,
      });

      this.sidebarItems.push({
        name: 'Calls',
        icon: 'fa-phone',
        route: '/agent/dashboard',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'Call Reasons',
        icon: 'fa-phone',
        route: '/agent/callreasons',
        selected: false,
        isSharedFiles: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'SharedFiles',
        icon: 'fa-file',
        route: '/agent/sharedfiles',
        isSharedFiles: true,
        selected: false,
        offlineFiles: this.unResolvedFilesCounter,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'Scheduled callbacks',
        icon: 'fa-calendar-days',
        route: '/agent/reschedulecalls',
        isSharedFiles: false,
        selected: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: this.unResolvedCallReschedulesCounter,
        unResolvedLedgersCounter: null,
        unverifiedMobileUsersCounter: null
      });

      this.sidebarItems.push({
        name: 'Ledgers',
        icon: 'fa-solid fa-file-invoice',
        route: '/agent/ledgers',
        isSharedFiles: false,
        selected: false,
        offlineFiles: 0,
        unResolvedCallReschedulesCounter: null,
        unResolvedLedgersCounter: this.unResolvedLedgersCounter,
        unverifiedMobileUsersCounter: null
      });
    }

    this.updateSelectedState();
  }

  updateSelectedState(): void {
    const currentUrl = this.router.url;

    this.sidebarItems.forEach((item) => {
      if (item.route === currentUrl) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }

  private retrieveUserRoles(): void {
    this.isAdmin = this.roles.includes(Roles.Admin);
    this.isAgent = this.roles.includes(Roles.Agent) || this.roles.includes(Roles.Leasing);
    this.isLockboxServiceManager = this.roles.includes(Roles.LockboxServiceManager);
    this.isMarketingAgent = this.roles.includes(Roles.Marketing);
  }

  private async setCurrentUserData(): Promise<void> {
    const userClaims = await this.authenticatedUserService.getClaims();
    this.name = `${userClaims.firstName} ${userClaims.lastName}`;
    if (Array.isArray(userClaims.roles))
      this.roles = userClaims.roles.join(', ');
    else this.roles = userClaims.roles;
  }

  onItemSelect(event: SidebarItem): void {
    this.sidebarItems.forEach((item) => {
      item.selected = event.name === item.name;
    });
  }

  onSidebarSelect(): void {
    this.sidebarVisible = !this.sidebarVisible;
  }
}
