import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RefreshRateService {
    refreshRate = new Subject<boolean>();
    name = "";
}
