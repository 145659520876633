import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebrtcService, SignalRService, EventBusService } from 'shared-services';
import { Environment } from 'shared-services/lib/environment.interface';

@Injectable({
  providedIn: 'root'
})

export class MobileHubSignalRService extends SignalRService {
  constructor(
    webrtc: WebrtcService,
    http: HttpClient,
    router: Router,
    activeRoute: ActivatedRoute,
    httpBackend: HttpBackend,
    eventBusService: EventBusService,
    @Inject('environment') environment: Environment
  ) {
    super(webrtc, http, router, activeRoute, httpBackend, eventBusService, environment);
    console.log("mobilehubCalledHere")
    this.connectToHub(true, environment.apiURL + `/mobilehub`);
  }
}

export enum MobileHub {
  Tick = "tick",
  Tock = "tock",
  ActivateUser = "Activate"
}
