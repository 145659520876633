<div
  class="sidebar-item"
  (click)="navigateTo(item)"
  (keydown.enter)="navigateTo(item)"
  (keydown.space)="navigateTo(item)" [ngClass]="{ 'selected': item.selected }" tabindex="0">
    <i class="fa-solid" [ngClass]="item.icon"></i>
    <span class="name">{{item.name}}</span>
    <p-tag *ngIf="item.isSharedFiles === true"  styleClass="float-tag" value="{{item.offlineFiles}}" pTooltip="{{item.offlineFiles}} Unresolved files"></p-tag>
    <p-tag *ngIf="item.unResolvedCallReschedulesCounter !== null && item.unResolvedCallReschedulesCounter > 0"  styleClass="float-tag" value="{{item.unResolvedCallReschedulesCounter}}" pTooltip="{{item.unResolvedCallReschedulesCounter}} Unresolved calls"></p-tag>
    <p-tag *ngIf="item.unResolvedLedgersCounter !== null && item.unResolvedLedgersCounter > 0"  styleClass="float-tag" value="{{item.unResolvedLedgersCounter}}" pTooltip="{{item.unResolvedLedgersCounter}} Unresolved ledgers"></p-tag>
    <p-tag *ngIf="item.unverifiedMobileUsersCounter !== null && item.unverifiedMobileUsersCounter > 0"  styleClass="float-tag" value="{{item.unverifiedMobileUsersCounter}}" pTooltip="{{item.unverifiedMobileUsersCounter}} Unverified mobile users"></p-tag>
</div>
