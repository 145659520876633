<div class="notNative" >
    <p-dialog header="{{ 'Terminal.ScheduleCallback' | translate }}" [(visible)]="displayDialog" [closable]="false" [style]="{width: dialogWidth }">
        <p-messages *ngIf="idleState !== 'NOT_IDLE'" severity="warn">
            <ng-template pTemplate>
                <i class="p-message-icon pi pi-exclamation-triangle"></i>
                <span class="p-message-summary">Warning</span>
                <span class="p-message-detail">
                    {{ 'Idle.ClosingSoon' | translate: { value: countdown } }}
                </span>
            </ng-template>
        </p-messages>
        <p-messages *ngIf="description" severity="{{!isNotUnexpected ? 'warn':'info'}}">
            <ng-template pTemplate>
                <i class="p-message-icon pi pi-info-circle"></i>
                <span class="p-message-detail">
                    {{ description | translate }}
                </span>
            </ng-template>
        </p-messages>
        <p-messages *ngIf="showErrorMessage" [(value)]="errorMessages"></p-messages>
        <div class="card" *ngIf="isNotUnexpected">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6 lg:col-4">
                    <span>{{'Terminal.PhoneNo' | translate }}</span>
                    <p-inputMask mask="(999) 999-9999" required name="phoneNo" ngDefaultControl [(ngModel)]="phoneNo" placeholder="(999) 999-9999"></p-inputMask>
                </div>
                <div class="field col-12 md:col-6 lg:col-4">
                    <span>{{'Terminal.Email' | translate }}</span>
                    <input pInputText type="email" email name="email"[(ngModel)]="email" placeholder="j***@s***.com" />
                </div>
                <div class="field col-12 md:col-6 lg:col-4" style="margin-top:20px;">
                    <span class="p-float-label p-fluid">
                        <textarea pInputTextarea name="remark" ngDefaultControl id="remark" [cols]="30" [rows]="4" [(ngModel)]="remark"></textarea>
                        <label for="remark">{{'Terminal.Note' | translate }}</label>
                    </span>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer" *ngIf="isNotUnexpected">
            <div class="p-field p-col-12 p-md-12">
                <button type="button" (click)="rescheduleCall()" pButton>{{'Terminal.Submit' | translate }}</button>
                <button pButton pRipple class="p-button-text" type="button" pButton mat-dialog-close="true">{{'Terminal.Cancel' | translate }}</button>
            </div>
        </ng-template>
        <ng-template pTemplate="footer" *ngIf="!isNotUnexpected">
            <div class="p-field p-col-12 p-md-12">
                <button type="button" (click)="showRescheduleCallbackDialog()" pButton>{{'Terminal.ScheduleCallback' | translate }}</button>
                <button type="button" (click)="makeAnotherCall()" pButton mat-dialog-close="true">{{'Terminal.MakeAnotherCall' | translate }}</button>
                <button pButton pRipple class="p-button-text" type="button" pButton mat-dialog-close="true">{{'Terminal.Cancel' | translate }}</button>
            </div>
        </ng-template>
    </p-dialog>
</div>
