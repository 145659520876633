import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders,
  HttpClient,
  HttpBackend,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EventData } from 'shared-models';
import { LoggingApiService, EventBusService, CorrelationIdInterceptorService } from 'shared-services';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true,
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private httpClient: HttpClient;
  private localStorageRetriesKey = 'redirectToLoginRetries';

  constructor(
    httpBackend: HttpBackend,
    private eventBusService: EventBusService,
    private logger: LoggingApiService
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<object>> {
    req = req.clone({ withCredentials: !req.url.includes('onelogin') });
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('auth/signin') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const loggedInValue = localStorage.getItem('loggedIn') ?? 'false';
      const loggedIn = !!loggedInValue;
      if (loggedIn) {
        return this.refreshToken().pipe(
          switchMap((res: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(res);
            return next.handle(request);
          }),
          catchError((err) => {
            // NOTE: While resolving linting, noticed that the following block
            // is completely unused

            // const redirectToLoginRetries = localStorage.getItem(
            //   this.localStorageRetriesKey
            // );
            // const parsedRedirectToLoginRetries = redirectToLoginRetries
            //   ? parseInt(redirectToLoginRetries)
            //   : 0;
            setTimeout(() => {
              this.eventBusService.emit(
                new EventData(
                  'logout',
                  true
                )
              );
            }, 500);

            return throwError(err);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap(() => next.handle(request))
    );
  }

  private refreshToken() {
    return this.httpClient.get(
      environment.apiURL + '/api/accounts/refreshtoken',
      httpOptions
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CorrelationIdInterceptorService, multi: true },
];
