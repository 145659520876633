import { Component, Inject, Optional } from '@angular/core';
import { NotificationDTO } from 'shared-models'
import { ToastService } from 'shared-services';

@Component({
  selector: 'app-data-missing-error',
  templateUrl: './data-missing-error.component.html',
  styleUrls: ['./data-missing-error.component.scss']
})
export class DataMissingErrorComponent {

  constructor(
    @Optional() @Inject('NotificationDTO') public notificationDTO: NotificationDTO,
    private toastService: ToastService
  ) {

  }
  close(): void {
    this.toastService.close();
  }
}
