import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoomData } from 'shared-models';

@Component({
  selector: 'app-agent-call-invite-dialog',
  templateUrl: './agent-call-invite-dialog.component.html',
  styleUrls: ['./agent-call-invite-dialog.component.scss']
})
export class AgentCallInviteDialogComponent {
  address = "";
  unit = "";
  invited = "";
  title = "Incoming Call Invite";

  constructor(@Inject(MAT_DIALOG_DATA) public data: RoomData) {
    this.address = data.Address;
    this.unit = data.UnitName || "Leasing";
    this.invited = data.InvitedBy;
    this.title = "Call Invitation";
  }
}
