<div class="user-info-container">
    <div class="info">
        <span class="name">{{name}}</span>
        <span class="description">{{description}}</span>
    </div>
    <div class="avatar">
        {{setInitials()}}
    </div>
    <button
      pButton
      type="button"
      icon="pi pi-angle-down"
      class="p-button-rounded p-button-text"
      (click)="menu.toggle($event)"
      aria-label="Toggle menu"
    ></button>
        <p-menu #menu [style]="{'width': '110px', 'font-size': '0.8em'}" appendTo="body" [popup]="true" [model]="items">
        </p-menu>
</div>
