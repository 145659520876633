import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoggingApiService, AuthenticatedUserService } from 'shared-services';
@Component({
  selector: 'app-user-idle-dialog',
  templateUrl: './user-idle-dialog.component.html',
  styleUrls: ['./user-idle-dialog.component.scss']
})
export class UserIdleDialogComponent implements OnInit {
  public timeRemaining = 0;
  public minutesRemaining = 0;
  public minutes = 0;
  private timeout = null;
  constructor(
    private authService: AuthService,
    private logger: LoggingApiService,
    private authenticatedUserService: AuthenticatedUserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.minutes = data.minutes;
  }

  ngOnInit(): void {

    this.secondTimer();
  }

  continue(): void {
    try {
      clearTimeout(this.timeout);
      localStorage.setItem("isTimerStarted", "false");
    }
    catch (error) {
      this.logger.logError(error);
    }
  }

  logout(): void {
    try {
      localStorage.removeItem('loggedIn');
      this.authService.signOut();
      this.authenticatedUserService.logout().subscribe();
    }
    catch (error) {
      this.logger.logError(error);
    }
  }

  secondTimer() {
    try {
      let remainingSeconds = this.minutes * 60;
      this.timeout = setInterval(() => {
        if (remainingSeconds == 0) {
          this.logout();
          clearInterval(this.timeout)
        }
        else {
          remainingSeconds--;
        }
        const mins = (remainingSeconds / 60);
        this.minutesRemaining = Math.floor(mins);
        this.timeRemaining = (remainingSeconds - (this.minutesRemaining * 60));

      }, 1000)
    }
    catch (error: unknown) {
      if (error instanceof Error) {
        console.error('An error occurred:', error.message);
      } else {
        console.error('An unknown error occurred:', error);
      }
    }
  }
}
